import {
  categorization,
  commons,
  cpStationOptoins,
  info,
  Locales,
  machineList,
  measureUnits,
  menuCreator,
  menuType,
  menuUploader,
  productForm,
  productGroup,
  productSearchOptions,
  recipeBookType,
  storageUnit,
  timeUnit,
} from './types';

export const enLocale: Locales = {
  [commons.LOADING]: 'Loading',
  [commons.ACCEPT]: 'Accept',
  [commons.ADD]: 'Add',
  [commons.FILTERS]: 'Filters',
  [commons.RETURN]: 'Return',
  [commons.FINISH]: 'Finish',
  [commons.START]: 'Start',
  [commons.FINISHED]: 'Finished',
  [commons.NAME]: 'Name',
  [commons.SKU]: 'SKU',
  [commons.STATUS]: 'Status',
  [commons.GROUP]: 'Group',
  [commons.STEP]: 'Step',
  [commons.CHECKPOINT]: 'Checkpoint',
  [commons.DOES_NOT_APPLY]: 'Does not apply',
  [commons.DETAILS]: 'More details',
  [commons.CATEGORY]: 'Category',
  [commons.CATEGORIES]: 'Categories',
  [commons.STORES_IN]: 'Stores in',
  [commons.TO]: 'to',
  [commons.IT_APPEARS_IN_THE_RECIPE_OF_THE]: 'It appears in the recipe of the',
  [commons.THE_ITEM]: 'The item',
  [commons.FOR_THIS_REASON]: 'For this reason',
  [commons.IT_APPEARS_IN_THE_KITCHEN]: 'Appears in the kitchen',
  [commons.PRODUCT_TYPE]: 'Product Type',
  [commons.ASSEMBLED_PRODUCTION_IN]: 'Assembled production in',
  [commons.THERE_IS_NO_DATA_TO_DISPLAY]: 'There is no data to display',
  [commons.CONTINUE]: 'Continue',
  [commons.COPY]: 'Copy',
  [commons.RECEIVE]: 'Receive',
  [commons.PROGRESS]: 'Progress',
  [commons.PRODUCT_NAME]: 'Product Name',
  [commons.UNIT]: 'Unit',
  [commons.UNIT_IN_KDS]: 'Unit in KDS',
  [commons.UNITS]: 'Units',
  [commons.ACTIONS]: 'Actions',
  [commons.INIT_DATE]: 'Start Date',
  [commons.FINAL_DATE]: 'End Date',
  [commons.SEARCH]: 'Search',
  [commons.EXPORT]: 'Export',
  [commons.CANCEL]: 'Cancel',
  [commons.DELETE]: 'Delete',
  [commons.SAVE]: 'Save',
  [commons.SPECIFICATIONS]: 'Specifications',
  [commons.BACK]: 'Back',
  [commons.COUNTRIES]: 'Countries',
  [commons.COUNTRY]: 'Country',
  [commons.TYPE]: 'Type',
  [commons.SELECT_FILE]: 'Select file',
  [commons.KITCHENS]: 'Kitchens',
  [commons.KITCHEN]: 'Kitchen',
  [commons.QUANTITY]: 'Quantity',
  [commons.TIME]: 'Time',
  [commons.ENABLED]: 'Enabled',
  [commons.DISABLED]: 'Disabled',
  [commons.QUANTITY_IN_KDS]: 'Quantity in KDS',
  [commons.LAST_MODIFIED]: 'Last Modified',
  [commons.LAST_UPDATE]: 'Last Update',
  [commons.DETAIL_OF_THE_REASON]: 'Reason Detail',
  [commons.REASON]: 'Reason',
  [commons.PRIORITY_STATUS]: 'Priority Status',
  [commons.PRIORITY_TURN_OFF]: 'Priority Turn Off',
  [commons.PRIORITY_TURN_ON]: 'Priority Turn On',
  [commons.PROTOCOL]: 'Protocol',
  [commons.OFF]: 'Turned off',
  [commons.ON]: 'Turned on',
  [commons.ALL_TYPES]: 'All types',
  [commons.STORES_AFFECTED_BY_THE_PRODUCT]: 'Stores affected by the product',
  [commons.PRODUCTS_TURNED_OFF]: 'Products Turned Off',
  [commons.DATETIME]: 'Date / Time',
  [commons.EMPTY_RESULT]: 'No results',
  [commons.RECIPE_BOOK]: 'Recipe Book',
  [commons.SELECT_COUNTRY]: 'Select country',
  [commons.PHOTO]: 'Photo',
  [commons.ITEM]: 'Name',
  [commons.RESULT_SEARCH]: 'Search Results for',
  [commons.OF]: 'of',
  [commons.FOR]: 'for',
  [commons.IN]: 'in',
  [commons.CORRECT]: 'Correct',
  [commons.ERROR]: 'Error',
  [commons.CITY]: 'City',
  [commons.WITHOUT_OPTIONS]: 'No options',
  [commons.SELECT]: 'Select',
  [commons.SELECT_ALL]: 'Select All',
  [commons.LOADING_PROTOCOLS]: 'Loading protocols',
  [commons.SELECT_CITY]: 'Select city',
  [commons.SELECT_PLATFORM]: 'Select platform',
  [commons.PLATFORM]: 'Platform',
  [commons.PLATFORMS]: 'Platforms',
  [commons.MENU]: 'Menu',
  [commons.BRAND]: 'Brand',
  [commons.BRANDS]: 'Brands',
  [commons.SELECT_PAGE]: 'Select a page',
  [commons.DISABLE]: 'Disable',
  [commons.PRODUCT]: 'Product',
  [commons.PRODUCTS]: 'Products',
  [commons.GROUPS]: 'Groups',
  [commons.SELECT_BRAND]: 'Select brands for the product report',
  [commons.CURRENT_INVENTORY]: 'Current Inventory',
  [commons.SUPPLIES]: 'Supplies',
  [commons.SUPPLY]: 'Supply',
  [commons.UNIT_OF_MEASUREMENT]: 'Unit of Measurement',
  [commons.PRODUCTION_PERFORMANCE]: 'Production Performance',
  [commons.STATE]: 'State',
  [commons.STATION]: 'Station',
  [commons.USEFUL_LIFE]: 'Useful Life',
  [commons.ASSEMBLY]: 'Assembly',
  [commons.INVENTORY_GROUP]: 'Inventory Group',
  [commons.IVA_MAJOR_VALUE]: 'IVA Major Value',
  [commons.TAX_GROUP_CODE]: 'Finished Product Taxes',
  [commons.IGV]: 'IGV',
  [commons.OVER_RECEIPT_CODE]: 'Receipt Code',
  [commons.GROUPS_ACCOUNTING_RECORD_IVA]: 'IVA Accounting Group',
  [commons.STORAGE]: 'Storage',
  [commons.NCM_CODE]: 'NCM Code',
  [commons.SHOW_AS_COMBO]: 'Visible as Combo',
  [commons.SHOW_AS_TOPPING]: 'Visible as Topping',
  [commons.IS_VARIABLE_COMBO]: 'Is Variable Combo',
  [commons.HIDDEN]: 'Hidden',
  [commons.RECIPE_IN_PRINTED_ORDER]: 'Recipe in Printed Order',
  [commons.IS_TOPPING]: 'Is Topping',
  [commons.CATEGORIZATION]: 'Categorization',
  [commons.MANDATORY]: 'Mandatory',
  [commons.EDIT]: 'Edit',
  [commons.STORES]: 'Stores',
  [commons.STORE]: 'Store',
  [commons.ASSIGN_MODIFIER]: 'Assign Modifiers',
  [commons.ADD_INSTRUCTIONS]: 'Add Instructions',
  [commons.UNITS_ABBREVIATION]: 'Unit',
  [commons.OTHERS]: 'Others',
  [commons.WEIGHT]: 'Weight',
  [commons.EXIT]: 'Exit',
  [commons.YES]: 'Yes',
  [commons.NO]: 'No',
  [commons.CURRENT]: 'Current',
  [commons.NEW]: 'New',
  [commons.DIFFERENCE]: 'Difference',
  [commons.NEW_QUANTITY]: 'New Quantity',
  [commons.ULTRAPROCESSED_TAX]: 'Ultra-processed Tax',
  [commons.ADD_PRODUCT]: 'Add Product',
  [commons.PRODUCTS_THAT_CAN_REPLACE_IT]: 'Products that can replace it',
  [commons.FINISHED_PRODUCTS]: 'Finished Products',
  [commons.SUPPLIES_OR_PRODUCTIONS]: 'Supplies or Productions',
  [commons.SEARCH_FOR_INPUTS_PRODUCTS_OR_PROTOCOL]: 'Search by supplies, products or protocol',
  [commons.SWITCHING_INPUTS_ON_AND_OFF]: 'Switching supplies on and off',
  [commons.SELECT_A_KITCHEN]: 'Select a kitchen',
  [commons.SELECT_THE_KITCHEN_TO_MONITOR]:
    'Select the kitchen to monitor and manage supplies, products and protocols',
  [commons.ON_AND_OFF]: 'On and off',
  [commons.PRODUCTS_RELATED_TO_THE_SUPPLIE]: 'Products related to the supplie',
  [commons.BY_PROTOCOLS]: 'By protocols',
  [commons.PROTOCOLS]: 'Protocols',
  [commons.SHUTDOWN_DUE_TO_PROTOCOLS]: 'Shutdown due to protocols',
  [commons.SHUTDOWN_BY_STOCKOUTS]: 'Shutdown by Stockouts',
  [commons.ON_]: 'Turnded on',
  [commons.OFF_]: 'Turnded off',
  [commons.BY_BRAND]: 'By brand',

  [recipeBookType.INSTRUCTIONS_FOR_PREPARATION]: 'Instructions for Preparation',
  [recipeBookType.ADD_LIFE_CYCLE]: 'Add Life Cycle',
  [recipeBookType.LIFE_CYCLE]: 'Life Cycle',
  [recipeBookType.STORAGE_TYPE]: 'Storage Type',
  [recipeBookType.RECORD_CARD]: 'View Detail',
  [recipeBookType.SEARCH_PRODUCTS]: 'Search products, productions, and supplies',
  [recipeBookType.PRODUCTS_HAS_CONTAINS]: 'Products containing',
  [recipeBookType.TO_SEE_PRODUCTS]: 'to view products',
  [recipeBookType.CREATE_NEW_ITEM]: 'Create New Item',
  [recipeBookType.DOWNLOAD_RECIPE]: 'Download Recipe',
  [recipeBookType.ENABLE_ITEM]: 'Change Status',
  [recipeBookType.ENABLE]: 'Enable',
  [recipeBookType.CAN_NOT]: 'Cannot',
  [recipeBookType.CAN_NOT_DISABLE]: 'Cannot disable',
  [recipeBookType.CONFIRM_DISABLE]: 'Are you sure you want to disable the item?',
  [recipeBookType.CONFIRM_DISABLE_TEXT]: 'If you disable the item',
  [recipeBookType.CAN_NOT_VIEW]: 'it will not be visible in the recipe book or recipes',
  [recipeBookType.I_SURE]: 'I am sure',
  [recipeBookType.CONFIRM_ENABLE]: 'Are you sure you want to enable the item?',
  [recipeBookType.CONFIRM_ENABLE_TEXT]: 'If you enable the item',
  [recipeBookType.CAN_VIEW]: 'it will be visible in the recipe book and recipes',
  [recipeBookType.CREATE_RECIPE]: 'Create Recipe',
  [recipeBookType.HAS_OPERATOR]: 'Needs an operator',
  [recipeBookType.MACHINE_REQUIRED]: 'Machine Required',
  [recipeBookType.SELECT_MACHINE]: 'Select the machine',
  [recipeBookType.STEP_MACHINE]: 'Machine for the step',
  [recipeBookType.DETAIL_CHECKPOINT]: 'Detail the checkpoint',
  [recipeBookType.MESSAGE_SELECT_COUNTRY_PRODUCT_TYPE]:
    'Select the country and product type to create the recipe',
  [recipeBookType.APPEARANCE]: 'Appearance',
  [recipeBookType.COLOR]: 'Color',
  [recipeBookType.SMELL]: 'Smell',
  [recipeBookType.TASTE]: 'Taste',
  [recipeBookType.TEXTURE]: 'Texture',
  [recipeBookType.VISCOSITY]: 'Viscosity',
  [recipeBookType.ENTER_CHARACTERISTICS_OF]: 'Enter the characteristics of',
  [recipeBookType.ENTER_THE]: 'Enter the',
  [recipeBookType.SENSORY_CHARACTERISTICS]: 'Organoleptic',
  [recipeBookType.UNITY_PER_PACKAGE]: 'Unit per Package',
  [recipeBookType.GENERAL]: 'General',
  [recipeBookType.UNITY_PER_WEIGHT]: 'Weight per Unit',
  [recipeBookType.PACKING_TEMPERATURE]: 'Packing Temperature',
  [recipeBookType.PRODUCT_TEMPERATURE]: 'Product Temperature',
  [recipeBookType.ENTER_PRODUCT_TEMPERATURE]: 'Enter the packing temperature',
  [recipeBookType.PROTEIN]: 'Protein',
  [recipeBookType.VINAIGRETTE]: 'Vinaigrette Sauce',
  [recipeBookType.SAUCES]: 'Sauces',
  [recipeBookType.GRAINS]: 'Grains',
  [recipeBookType.BAKING]: 'Desserts',
  [recipeBookType.BASE]: 'Base',
  [recipeBookType.DRINK]: 'Drink',
  [recipeBookType.PREPARED_DRINK]: 'Prepared Drink',
  [recipeBookType.COVERINGS]: 'Coverings',
  [recipeBookType.DONATION]: 'Donation',
  [recipeBookType.PACKAGING]: 'Packaging',
  [recipeBookType.BAKERY]: 'Bakery',
  [recipeBookType.MAIN_DISH]: 'Main Dish',
  [recipeBookType.SIDES]: 'Side Dishes',
  [recipeBookType.DESSERTS]: 'Desserts',
  [recipeBookType.SEASONAL_SAUCES]: 'Seasonal Sauces',
  [recipeBookType.TOPPING]: 'Topping',
  [recipeBookType.BREADMAKING]: 'Breadmaking',
  [recipeBookType.SUBPRODUCTION]: 'Subproduction',
  [recipeBookType.CP_STATION]: 'CP Station',
  [recipeBookType.KITCHEN_STATION]: 'Kitchen Station',
  [recipeBookType.VACUUM_PACKING]: 'Vacuum Packing',

  [recipeBookType.HOT]: 'Hot',
  [recipeBookType.FRYING]: 'Frying',
  [recipeBookType.MIX]: 'Mixed',
  [recipeBookType.COLD]: 'Cold',
  [recipeBookType.BAKERY_PASTRY_SHOP]: 'Bakery/Pastry Shop',
  [recipeBookType.STEP_DURATION]: 'Step Duration',
  [recipeBookType.DURATION]: 'Duration',
  [recipeBookType.SHRINKAGE_PERCENTAGE]: 'Shrinkage Percentage',
  [recipeBookType.PERCENTAGE]: 'Percentage',
  [recipeBookType.ENTER_THE_PERCENTAGE_OF_SHRINKAGE_FOR_ALL_CITIES]:
    'Enter the shrinkage percentage for all cities',
  [recipeBookType.FACTOR]: 'Conversion Factor',
  [recipeBookType.SELECT_SUPPLY]: 'Select Supply',
  [recipeBookType.REPLACE_RECIPE_TITLE]: 'Replace in Recipes',
  [recipeBookType.SELECT_SUPPLY_MODAL_TITLE]: 'Select New Supply',
  [recipeBookType.UPDATE_DIFFERENCE_MESSAGE]:
    'The recipe will be adjusted with {{quantity}} {{unit}}',
  [recipeBookType.UPDATE_FACTOR_MESSAGE]:
    '{{factor}} {{unit}} of {{name}} equals 1 {{selectedProductUnit}} of {{selectedProductName}}',

  [menuType.DEFAULT_ERROR_MSG]: 'An error occurred while loading the menu',
  [menuType.STARTED_LOADING_MENU]: 'Menu loading started',
  [menuType.UPLOAD_MENU_TO_VIEW]: 'Upload the menu you want to view',
  [menuType.UPLOAD_MENU]: 'Upload Menu',

  [menuCreator.ADD_AISLE]: 'Add Aisle',
  [menuCreator.PUBLISH_MENU]: 'Publish Menu',
  [menuCreator.SAVE_DRAFT]: 'Save Draft',
  [menuCreator.DRAFT]: 'Draft',
  [menuCreator.SETTING]: 'Setting',
  [menuCreator.MENU]: 'Menu',
  [menuCreator.MENUS]: 'Menus',
  [menuCreator.SEARCH_MENU]: 'Search Menu',
  [menuCreator.SEARCH_COUNTRY]: 'Search Country',
  [menuCreator.CHOOSE_COUNTRY]: 'Choose a country to view menus from that country',
  [menuCreator.CREATE_NEW_MENU]: 'Create New Menu',
  [menuCreator.MENU_NAME]: 'Menu Name',
  [menuCreator.BRAND]: 'Brand',
  [menuCreator.STORES]: 'Stores',
  [menuCreator.LAST_UPDATE]: 'Last Update',
  [menuCreator.BY]: 'by',
  [menuCreator.ACTIONS]: 'Actions',
  [menuCreator.LIBRARY]: 'Template Library',
  [menuCreator.SAVE_AISLE]: 'Save Aisle',
  [menuCreator.ADD_MENU_ITEM]: 'Add Menu Item',
  [menuCreator.AISLE_NAME]: 'Aisle Name',
  [menuCreator.SAVE_MENU_ITEM]: 'Save Menu Item',
  [menuCreator.SAVE_MODIFIER_TEMPLATE]: 'Save as Template',
  [menuCreator.ADD_MODIFIER_GROUP]: 'Add Modifier Group',
  [menuCreator.PLATFORM_DESCRIPTION]: 'Platform Description',
  [menuCreator.PLATFORM_NAME]: 'Platform Name',
  [menuCreator.PRICE]: 'Price',
  [menuCreator.RECIPE_BOOK_NAME]: 'Recipe Book Name',
  [menuCreator.CHOOSE_IMAGE]: 'Choose the Photo',
  [menuCreator.DELETE_MODAL_TITLE]: 'Do you want to delete this aisle?',
  [menuCreator.DELETE_MODAL_TEXT]: 'If you want to delete it, click "Delete"',
  [menuCreator.DELETE_MODAL_CANCEL]: 'Cancel',
  [menuCreator.DELETE_MODAL_ACTION]: 'Delete',
  [menuCreator.MENU_MANAGER_TITLE]: 'Menu Manager',
  [menuCreator.CREATE_MENU_MODAL_TITLE]: 'Create a New Menu',
  [menuCreator.COPY_MENU_MODAL_ACTION]: 'Copy Menu',
  [menuCreator.COPY_MENU_MODAL_LABEL]: 'Enter the menu name',
  [menuCreator.COPY_MENU_MODAL_TITLE]: 'Enter the Copy Name',
  [menuCreator.CREATE_MENU_MODAL_TEXT]: 'Enter the necessary information to create the menu',
  [menuCreator.CREATE_MENU_MODAL_NAME]: 'Menu Name',
  [menuCreator.CREATE_MENU_MODAL_CANCEL]: 'Cancel',
  [menuCreator.CREATE_MENU_MODAL_ACTION]: 'Create',
  [menuCreator.LOADING_COPY_MENU_TITLE]: 'Something is cooking!',
  [menuCreator.LOADING_COPY_MENU_DETAIL]: 'In a few seconds, your menu will be ready',
  [menuCreator.LOADING_COPY_MENU_BUTTON]: 'BACK TO THE MENU TABLE',
  [menuCreator.DELETE_MENU_ITEM_MODAL_TITLE]: 'Do you want to delete this menu item?',
  [menuCreator.DELETE_MENU_ITEM_MODAL_TEXT]: 'If you want to delete it, click "Delete"',
  [menuCreator.MENU_ITEMS]: 'Menu Items',
  [menuCreator.ENTER_AISLE_NAME]: 'Enter the Aisle Name',
  [menuCreator.SEARCH_SKU_NAME]: 'Search by SKU or Name',
  [menuCreator.ENTER_PLATFORM_NAME]: 'Enter the Platform Name',
  [menuCreator.ENTER_PRICE]: 'Enter the Price',
  [menuCreator.ENTER_DESCRIPTION]: 'Enter the Description',
  [menuCreator.THERE_IS_ALREADY_A_GROUP_WITH_THIS_NAME]: 'A group with this name already exists',
  [menuCreator.THE_VALUE_CANNOT_BE_NEGATIVE]: 'The value cannot be negative',
  [menuCreator.MODIFIER_GROUP_NOT_FOUND]: 'Modifier Group Not Found',
  [menuCreator.ENTER_A_NAME_FOR_THE_NEW_GROUP]: 'Enter a Name for the New Modifier Group',
  [menuCreator.FILL_IN_THE_REQUIRED_DATA_IN_THE_MODIFIERS]:
    'Fill in the required data for the modifiers',
  [menuCreator.A_PRODUCT_WITH_THIS_SKU_ALREADY_EXISTS_IN_THE_GROUP]:
    'A product with this SKU already exists in the group',
  [menuCreator.MODIFIER_GROUP]: 'Modifier Group',
  [menuCreator.DELETE_MODIFIER_GROUP]: 'Do you want to delete this modifier group?',
  [menuCreator.DELETE_MODIFIER]: 'Do you want to delete this modifier?',
  [menuCreator.MODIFIER_GROUP_NAME]: 'Modifier Group Name',
  [menuCreator.ENTER_THE_GROUP_NAME]: 'Enter the Group Name',
  [menuCreator.MINIMUM]: 'Minimum',
  [menuCreator.MAXIMUM]: 'Maximum',
  [menuCreator.OPTIONAL]: 'Optional',
  [menuCreator.PREVIOUSLY_CREATED]: 'Previously Created',
  [menuCreator.DRAG_MODIFIERS]: 'Drag the modifiers to this section',
  [menuCreator.CREATE_A_NEW_MODIFIER]: 'Create a New Modifier',
  [menuCreator.ADD_MODIFIERS]: 'Add Modifiers',
  [menuCreator.SAVE_GROUP_MODIFIERS]: 'Save Modifier Group',
  [menuCreator.EDIT_GROUP_MODIFIERS]: 'Edit Modifier Group',
  [menuCreator.MODIFIER_GROUPS]: 'Modifier Groups',
  [menuCreator.DRAG_MODIFIERS_GROUPS]: 'Drag modifier groups to this section',
  [menuCreator.CREATE_A_NEW_GROUP]: 'Create a New Group',
  [menuCreator.ADD_MODIFIER_GROUPS]: 'Add Modifier Groups',
  [menuCreator.ADD_THE_GROUPS]: 'Add the Groups of',
  [menuCreator.SHOW_PUBLISHED]: 'View Published',
  [menuCreator.MODIFIERS]: 'Modifiers',
  [menuCreator.ADD_THE_MODIFIERS]: 'Add the Modifiers',
  [menuCreator.DESCRIPTION]: 'Description',
  [menuCreator.EDIT_MENU]: 'Edit Menu',
  [menuCreator.VIEW_MENU]: 'View Menu',
  [menuCreator.SAVE_SETTINGS]: 'Save Settings',
  [menuCreator.NONE_RESPONSE]: 'No Response',
  [menuCreator.SEND]: 'Send',
  [menuCreator.REFUSED]: 'Refused',
  [menuCreator.PUBLISHED]: 'Published',
  [menuCreator.DATE_TEXT_DRAFT]: 'Last modified ',
  [menuCreator.RESTORE_PUBLISHED]: 'Restore Published',
  [menuCreator.RESTORE_MODAL_TITLE]:
    'Do you want to delete the draft and restore the published version?',
  [menuCreator.RESTORE_MODAL_TEXT]: 'Accepting will delete the changes made in the current draft',
  [menuCreator.MENU_WITH_CHANGES]: 'Menu with unpublished changes',
  [menuCreator.REASSIGN_STORE]: 'Reassign Store',
  [menuCreator.SCHEDULE]: 'Schedule',
  [menuCreator.STORE_SCHEDULE]: 'Store Schedule',
  [menuCreator.MONDAY]: 'Monday',
  [menuCreator.TUESDAY]: 'Tuesday',
  [menuCreator.WEDNESDAY]: 'Wednesday',
  [menuCreator.THURSDAY]: 'Thursday',
  [menuCreator.FRIDAY]: 'Friday',
  [menuCreator.SATURDAY]: 'Saturday',
  [menuCreator.SUNDAY]: 'Sunday',
  [menuCreator.MORE_INFO_ONE]: 'For changes to the schedule, contact',
  [menuCreator.MORE_INFO_TWO]: 'the tech ops team',
  [menuCreator.DELETE_MODIFIER_GROUPS]: 'Delete Modifier Group',
  [menuCreator.MODIFIER_GROUPS_SINGULAR]: 'Modifier Group',
  [menuCreator.CREATE_A_NEW_GROUP_OF_MODIFIERS]: 'Create a New Modifier Group',
  [menuCreator.EDIT_MODIFIER]: 'Edit Modifier',
  [menuCreator.DELETE_MODIFIERS]: 'Delete Modifier',
  [menuCreator.SAVE_MODIFIER]: 'Save Modifier',
  [menuCreator.ALERT_MAX_VALUE]:
    'The maximum number of modifiers is greater than the group´s maximum value',
  [menuCreator.INTERNAL_NAME_MODIFIER_GROUP]: 'Internal Name of the Modifier Group',
  [menuCreator.INTERNAL_NAME]: 'Enter the Internal Name',
  [menuCreator.FIND_THE_MODIFIER_GROUP]: 'Search for the Modifier Group',
  [menuCreator.WRITE_THE_NAME_OF_THE_GROUP]: 'Enter the Group Name',
  [menuCreator.FIND_THE_MODIFIERS]: 'Search for the Modifiers',
  [menuCreator.WRITE_THE_NAME_OF_THE_MODIFIER]: 'Enter the Modifier Name',
  [menuCreator.EDIT_MENU_ITEM]: 'Edit Menu Item',
  [menuCreator.EDIT_AISLE]: 'Edit Aisle',
  [menuCreator.SELECTED]: 'You selected',
  [menuCreator.ALERT_NO_CHILDREN]: 'Add at least one modifier',
  [menuCreator.MAX]: 'Max',
  [menuCreator.MIN]: 'Min',
  [menuCreator.THERE_ARE_NO_RESULTS_FOR]: 'No results for',
  [menuCreator.CITY_ZONE]: 'City / Zone',
  [menuCreator.STAY_IN_MENU]: 'Stay in Menu',
  [menuCreator.BACK_TO_BEGINNING]: 'Back to Beginning',
  [menuCreator.THE_MENU]: 'The Menu',
  [menuCreator.SAVED_MENU_TITLE]: 'has been saved as a draft',
  [menuCreator.AFTER_PUBLISH_MENU_TITLE]: 'has been published',
  [menuCreator.SAVED_MENU_TEXT]: 'Check the menu status in the main table of the manager',
  [menuCreator.PUBLISH_MENU_TITLE]: 'Do you want to publish {{menuName}}?',
  [menuCreator.ALL_GROUPS]: 'All Groups',
  [menuCreator.ALL_BRANDS]: 'All Brands',
  [menuCreator.ALL_CATEGORIES]: 'All Categories',
  [menuCreator.SELECT_COUNTRY]: 'Select a country',
  [menuCreator.PUBLISH_MENU_TEXT]: 'Clicking "Publish" will make this menu visible in the stores',
  [menuCreator.PRICE_CANNOT_BE_0]: 'Price cannot be 0',
  [menuCreator.CANNOT_REPEAT_A_MENU_ITEM]: 'Cannot repeat a menu item in the menu',
  [menuCreator.EXCEEDED_THE_MAXIMUM_NUMBER_OF_CHARACTERS_FOR_RAPPI]:
    'Exceeded the maximum character limit for Rappi',
  [menuCreator.MINIMUM_CAN_BE_3_CHARACTERS]: 'Minimum can be 3 characters',
  [menuCreator.YOU_HAVE_EXCEEDED_THE_MAXIMUM_NUMBER_OF_CHARACTERS_ALLOWED]:
    'You have exceeded the allowed character limit',
  [menuCreator.AND_YOU_ARE_AT]: 'and you are at',
  [menuCreator.CHARACTERS_REACHING_IFOOD_LIMIT]: 'characters from reaching the iFood limit',
  [menuCreator.MISSING]: 'Missing',
  [menuCreator.CHARACTERS_TO_REACH_THE_MAXIMUM_ALLOWED]: 'characters to reach the maximum allowed',
  [menuCreator.THERE_IS_ALREADY_A_MENU_WITH_THAT_NAME]: 'There is already a menu with that name',
  [menuCreator.YOU_HAVE_NOT_SAVED_ALL_THE_MENU_ITEMS]: 'You have not saved all menu items',
  [menuCreator.YOU_HAVE_NOT_SAVED_ALL_MODIFIER_GROUPS_IN_THE_MENU_ITEM]:
    'You have not saved all modifier groups in the menu items',
  [menuCreator.MENU_COPIED_SUCCESSFULLY]: 'Menu copied successfully',
  [menuCreator.THIS_MENU_COULD_NOT_BE_ASSIGNED_TO_STORES]:
    'This menu could not be assigned to stores',
  [menuCreator.NUMBER_OF_CHARACTERS_DOES_NOT_REACH_THE_MINIMUM]:
    'Number of characters does not reach the minimum',
  [menuCreator.NUMBER_OF_CHARACTERS_EXCEEDS_THE_MAXIMUM]:
    'Number of characters exceeds the maximum',
  [menuCreator.IN_THE_HALLWAY_NAME_FIELD]: 'In the aisle name field',
  [menuCreator.IN_THE_MODIFIER_GROUP_NAME_FIELD]: 'In the modifier group name field',
  [menuCreator.IN_THE_MENU_ITEM_DESCRIPTION]: 'In the menu item description',
  [menuCreator.IN_THE_MODIFIER_DESCRIPTION_FIELD]: 'In the modifier description field',
  [menuCreator.IN_THE_NAME_OF_THE_MENU_ITEM]: 'In the menu item name',
  [menuCreator.IN_THE_MODIFIER_NAME_FIELD]: 'In the modifier name field',
  [menuCreator.THE_NUMBER_OF_DIGITS_IN_THE_PRICE_EXCEEDS_THE_MAXIMUM_ALLOWED]:
    'The number of digits in the price exceeds the allowed maximum',
  [menuCreator.IN_THE_PRICE_FIELD_OF_THE_MENU_ITEM]: 'In the menu item price field',
  [menuCreator.IN_THE_AISLE]: 'In the aisle',
  [menuCreator.ALERT_VALUE_OF_THE_MODIFIERS]:
    'The maximum value of modifiers cannot be greater than the group’s maximum value',
  [menuCreator.AISLE]: 'Aisle',
  [menuCreator.PRODUCT]: 'Product',
  [menuCreator.THERE_IS_ALREADY_A_HALLWAY_WITH_THAT_NAME]:
    'There is already an aisle with that name',
  [menuCreator.CANNOT_SAVE_MODIFIER_GROUP]: 'Cannot save the modifier group',
  [menuCreator.CANNOT_SAVE_MODIFIER]: 'Cannot save the modifier',
  [menuCreator.CANNOT_SAVE_MODIFIER_GROUP_DETAIL]:
    'A modifier group with that name already exists, change the name if you still want to save it.',
  [menuCreator.CANNOT_SAVE_MODIFIER_DETAIL]:
    'A modifier with that name already exists, change the name if you still want to save it.',
  [menuCreator.SAVED_COMPONENT_FRAME]: 'has been saved as a template',
  [menuCreator.UPDATED_COMPONENT_FRAME]: 'has been updated',
  [menuCreator.SAVED_MODIFIER_GROUP_DETAIL]:
    'The modifier group was saved, you can now find it in the template library.',

  [menuCreator.SAVED_MODIFIER_DETAIL]:
    'The modifier was saved, you can now find it in the template library.',
  [menuCreator.RETURN_TO_EDIT]: 'Return to Edit',
  [menuCreator.SEE_LESS]: 'See Less',
  [menuCreator.SEE_MORE]: 'See More',
  [menuCreator.MINIMUM_VALUE_CANNOT_BE_GREATER_THAN_THE_MAXIMUM_VALUE]:
    'The minimum value cannot be greater than the maximum value',
  [menuCreator.SAVE_NAME]: 'Save Name',
  [menuCreator.WRITE_THE_NAME_OF_THE_MENU_ITEM]: 'Enter the menu item name',
  [menuCreator.FIND_THE_MENU_ITEMS]: 'Search for the menu item',
  [menuCreator.CREATE_A_MENU_ITEM]: 'Create a New Menu Item',
  [menuCreator.YOU_CAN_NOW_CONSULT_THE_SAVED_MENU_ITEM_IN_THE_TEMPLATE_LIBRARY]:
    'The saved menu item can now be found in the template library.',
  [menuCreator.CANNOT_SAVE_THIS_MENU_ITEM]: 'Cannot save this menu item',
  [menuCreator.TEMPLATE_FOR_THAT_MENU_ITEM_ALREADY_EXISTS]:
    'A template for this menu item already exists, change the product if you still want to save it.',
  [menuCreator.ADD_MENU_ITEMS]: 'Add Menu Items',
  [menuCreator.DRAG_MENU_ITEMS]: 'Drag menu items to this section',
  [menuCreator.COMPLETE_MENU_ITEM]: 'Save the menu item before proceeding',
  [menuCreator.COLLAPSE_ALL_AISLES]: 'Collapse All Aisles',
  [menuCreator.EXPAND_ALL_AISLES]: 'Expand All Aisles',
  [menuCreator.CANNOT_BE_DELETED]: 'Cannot be deleted',
  [menuCreator.DELETE_MENU]: 'Delete menu',
  [menuCreator.ASSIGNED_MENU]: 'This menu cannot be deleted because it is assigned to the stores:',
  [menuCreator.WANT_TO_DELETE_THIS_MENU]: 'Are you sure you want to delete this menu?',

  [categorization.CATEGORIZATION_BY_CITIES]: 'Categorization by Cities',

  [menuUploader.PERFECT_FILE]: 'The file is perfect!',
  [menuUploader.CLICK_TO_UPLOAD]: 'Click "UPLOAD MENU"',
  [menuUploader.REVIEWING_MENU]: 'We are reviewing the menu',

  [measureUnits.UN]: 'Unit',
  [measureUnits.G]: 'Grams',
  [measureUnits.GR]: 'Grams',
  [measureUnits.ML]: 'Milliliters',
  [measureUnits.KG]: 'Kilograms',

  [productForm.DRAFT]: 'In Draft',
  [productForm.PUBLISHED]: 'Published',
  [productForm.SAVE_DRAFT]: 'Save Draft',
  [productForm.SAVE_CHANGES]: 'Save Changes',
  [productForm.PUBLISH_CHANGES]: 'Publish Changes',
  [productForm.CREATED]: 'Created',
  [productForm.EDITED]: 'Edited',
  [productForm.PUBLISH]: 'Publish',
  [productForm.NCM_CODE_REQUIRED]: 'The NCM code is required',
  [productForm.COMPLETE_FORM]: 'Complete all fields',
  [productForm.AVOID_NEGATIVE_INVETORY]: 'Avoid negative inventory',
  [productForm.SHOW_RECIPE_ON_STATION]: 'Show recipe on station',

  // [timeUnit.EMPTY]: '',
  [timeUnit.HOUR]: 'Hours',
  [timeUnit.DAY]: 'Days',
  [timeUnit.WEEK]: 'Weeks',
  // [timeUnit.MONTH]: 'Months',

  //[storageUnit.EMPTY]: '',
  [storageUnit.FREEZING]: 'Freezing (-18°C)',
  [storageUnit.REFRIGERATION]: 'Refrigeration (1°C - 5°C)',
  [storageUnit.ROOM_TEMPERATURE]: 'Room Temperature (15°C - 30°C)',

  [machineList.STOVE]: 'Stove',
  [machineList.UNOX]: 'Unox',
  [machineList.GRIDDLE]: 'Griddle',
  [machineList.DEEP_FRYER]: 'Deep Fryer',
  [machineList.PIZZA_OVEN]: 'Pizza Oven',
  [machineList.SEALER]: 'Sealer',
  [machineList.INDUSTRIAL_BLENDER]: 'Industrial Blender',
  [machineList.MIXER]: 'Mixer',
  [machineList.DOUGH_MIXER]: 'Dough Mixer',
  [machineList.IMMERSION_BLENDER]: 'Immersion Blender',
  [machineList.ELECTRIC_MILL]: 'Electric Mill',
  [machineList.LANG_CINNABON_OVEN]: 'Lang Cinnabon Oven',
  [machineList.COOKING_KETTLE]: 'Cooking Kettle',
  [machineList.FERMENTER]: 'Fermenter',
  [machineList.DOUGH_SHEETER]: 'Dough Sheeter',
  [machineList.MIXER_60_LT]: 'Mixer 60Lt',
  [machineList.BLAST_CHILLER]: 'Blast Chiller',
  [machineList.COOLING]: 'Cooling',
  [machineList.MISE_EN_PLACE]: 'MEP',
  [machineList.PROCESS]: 'Process',
  [machineList.PACKAGING]: 'Packaging',

  [info.BRAND]:
    'Here you can view the list of brands and the platforms where this product or supply is available',
  [info.CATEGORIZATION]:
    'Categorization indicates which supplies are essential in a kitchen. Example: · Type AA are indispensable because we cannot sell finished products without them. · Type C are supplies that do not affect many finished products and are used in dishes with low volume and frequency of sales.',
  [info.EDITED]: 'Edited successfully',
  [info.HIDDEN]: 'The kitchen will not be able to view it',
  [info.SAVED]: 'Your draft changes have been saved',
  [info.PUBLISH]: 'Are you sure you want to publish the changes?',
  [info.WARNING_PUBLISH]:
    'Once published, you will not be able to edit the SKU. It is recommended to keep it in draft until catalog makes the change in Intuipos.',
  [info.PRODUCT_TYPE]: 'Finished product type.',
  [info.STATION]: 'Station where the product order should be sent.',
  [info.CATALOG]: '[Only for Catalog]',
  [info.CATEGORY]: 'Category required for accounting.',
  [info.HIDE_PRODUCT]: 'Select this if you want the finished product to be hidden by default.',
  [info.HIDE_TOPPING]:
    'Select this if you want it to appear as a default topping linked to a main dish.',
  [info.LOADING_PRODUCTS]: 'Loading products',
  [info.LOADING_SUPPLIES]: 'Loading supplies',
  [info.SELECT_BRAND_AND_PLATFORM]: 'Please select a brand and a platform to continue',
  [info.PRODUCTS_NOT_FOUND]: 'No products found for',
  [info.EMPTY_RECIPE]: 'Empty Recipe',
  [info.INCORRECT_AMOUNT]: 'Incorrect Amount',
  [info.ADD_AT_LEAST_ONE_INGREDIENT]: 'You must add at least one ingredient to the recipe',
  [info.IS_REQUIRED]: 'is required',
  [info.QUANTITY_LESS_THAN_OR_EQUAL_TO_0]:
    'No item in the recipe can have a quantity less than or equal to 0',
  [info.ORGANOLEPTIC_MANDATORY]: 'All organoleptic characteristics are mandatory',
  [info.SHELFLIFE_MANDATORY]: 'Enter at least one item in the useful life',

  [productGroup.PRODUCTION]: 'Production',
  [productGroup.FINISHED_PRODUCT]: 'Finished Product',
  [productGroup.SUPPLY]: 'Supply',
  [productGroup.COMBO]: 'Combo',
  [productGroup.GROCERY]: 'Grocery',
  [productGroup.DRINK]: 'Drinks',
  [productGroup.ALCOHOLIC_DRINK]: 'Alcoholic Drinks',
  [productGroup.MEAT]: 'Meats',
  [productGroup.BEER]: 'Beers',
  [productGroup.CIGARETTES]: 'Cigarettes',
  [productGroup.UNKNOWN]: 'Unknown',
  [productGroup.ADJUSTABLE_PACKAGING]: 'Adjustable Packaging',
  [productGroup.PACKAGING]: 'Packaging',
  [productGroup.FRUITS]: 'Fruits and Vegetables',
  [productGroup.DAIRY]: 'Dairy',
  [productGroup.MAINTENANCE]: 'Maintenance',
  [productGroup.BAKERY]: 'Bakery',
  [productGroup.UTENSILS]: 'Utensils',

  [productSearchOptions.ALL]: 'All cases with',
  [productSearchOptions.RECIPE]: 'Contained in the recipe',
  [productSearchOptions.COMBO]: 'Combos with the name',
  [productSearchOptions.FINISHED_PRODUCT]: 'Finished product with the name',
  [productSearchOptions.PRODUCTION]: 'Production with the name',
  [productSearchOptions.SUPPLY]: 'Supply with the name',

  [cpStationOptoins.HOT]: 'Hot',
  [cpStationOptoins.FRYING]: 'Frying',
  [cpStationOptoins.MIX]: 'Mixed',
  [cpStationOptoins.COLD]: 'Cold',
  [cpStationOptoins.BAKERYPASTRY]: 'Bakery-Pastry',
  [cpStationOptoins.PACKAGING]: 'Packaging',
};

import { LegacyCountry } from '../models/country';

export enum Env {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}
export enum Country {
  COL = 'COL',
  PER = 'PER',
  MEX = 'MEX',
  BRA = 'BRA',
  ECU = 'ECU',
}
export enum CurrencyCode {
  COL = 'COP',
  PER = 'PEN',
  MEX = 'MXN',
  ECU = 'USD',
  BRA = 'BRL',
}
export const countries: LegacyCountry[] = [
  {
    slug: 'brasil',
    displayName: 'Brasil',
    domain: 'br',
    prefix: 'bra',
    currencyCode: CurrencyCode.BRA,
  },
  {
    slug: 'colombia',
    displayName: 'Colombia',
    domain: 'co',
    prefix: 'col',
    currencyCode: CurrencyCode.COL,
  },
  {
    slug: 'mexico',
    displayName: 'México',
    domain: 'mx',
    prefix: 'mex',
    currencyCode: CurrencyCode.MEX,
  },
  {
    slug: 'peru',
    displayName: 'Perú',
    domain: 'pr',
    prefix: 'per',
    currencyCode: CurrencyCode.PER,
  },
  {
    slug: 'ecuador',
    displayName: 'Ecuador',
    domain: 'ec',
    prefix: 'ecu',
    currencyCode: CurrencyCode.ECU,
  },
];

export const getCurrencyCode = (country: Country): CurrencyCode | undefined => {
  const key = country as keyof typeof CurrencyCode;
  return CurrencyCode[key];
};

export const validateCurrency = (country: Country, value: string): boolean => {
  switch (country) {
    case Country.BRA:
    case Country.ECU:
    case Country.PER:
      return /^\d*\.?\d{0,2}$/.test(value);
    default:
      return /^\d*$/.test(value);
  }
};

export const ENVIRONMENT: string | undefined = process.env?.REACT_APP_ENV;
export const NPS_SHOW_DAY: number =
  process.env?.REACT_APP_NPS_SHOW_DAY === undefined
    ? 26
    : Number(process.env?.REACT_APP_NPS_SHOW_DAY);
export const NPS_TYPEFORM_ID: string | null = process.env?.REACT_APP_NPS_TYPEFORM_ID ?? '';
